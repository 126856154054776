
import './App.css';
import '@fontsource/quicksand/300.css';
import '@fontsource/quicksand/400.css';
import '@fontsource/quicksand/500.css';
import '@fontsource/quicksand/700.css';
import { Alert, Container, Grid, Snackbar, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Person } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DateTime } from 'luxon';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import IconButton from '@mui/material/IconButton';
import Download from '@mui/icons-material/Download';
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import Business from '@mui/icons-material/Business';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

const defaultTheme = createTheme({
    typography: {
        fontFamily: 'Quicksand',
        fontWeightBold: 700
    }
});

const styleModal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
};

const theEvent = {
    firstName: null,
    lastName: null,
    email: null,
    company: null,
    date: null,
    unix: null,
    idICS: null
}

const App = () => {

    const [snackbarOpened, setSnackbarOpened] = useState(false);
    const [dateSelected, setDateSelected] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [data, setData] = useState(null);

    const [errorSnackbarOpened, setErrorSnackbarOpened] = useState(false);

    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [fullName, setFullName] = useState('');
    const [emailError, setEmailError] = useState(false)

    const [minTime, setMinTime] = useState(dayjs('2024-09-13').set('hour', 10).set('minute', 30));
    const [maxTime, setMaxTime] = useState(dayjs('2024-09-13').set('hour', 18));


    const submit = useCallback((event, onAccept) => {
        event.preventDefault();
        onAccept();
        setLoading(true);

        let theEvent = {};

        theEvent.fullName = fullName;
        theEvent.unix = dateSelected.toISOString();
        theEvent.company = company;
        theEvent.email = email;

        try {
            fetch('https://ibc.kinedev.it/data.v2.php?bookit', {
                method: "POST",
                body: JSON.stringify(theEvent)
            }).then((res) => res.json()).then((res) => {
                setLoading(false);
                if (res?.message === '1') {
                    setLoading(false);
                    const dt = DateTime.fromISO(theEvent.unix);
                    // Ottieni la data di inizio e di fine nel fuso orario locale
                    const startDate = dt.toFormat('yyyy-LL-dd');
                    const endDate = dt.plus({ minutes: 15 }).toFormat('yyyy-LL-dd');
                    const startTime = dt.toFormat('HH:mm:ss');
                    const endTime = dt.plus({ minutes: 15 }).toFormat('HH:mm:ss');
                    setData({
                        startDate,
                        endDate,
                        startTime,
                        endTime
                    })
                    setSnackbarOpened(true);
                    setCompleted(true);
                } else {
                    setLoading(false);
                    setErrorSnackbarOpened(true);
                }
            })
            setSnackbarOpened(true);
            setCompleted(true);
        } catch (e) {
            setLoading(false);
            setErrorSnackbarOpened(true);
            console.log(e);
        }
    }, [dateSelected]);

    const onDateCalendarChange = (value, value2) => {
        if (value.$D === 13) {
            setMinTime(dayjs(new Date(value)).set('hour', 10).set('minute', 30))
            setMaxTime(dayjs(new Date(value)).set('hour', 18))
        } else if (value.$D === 16) {
            setMinTime(dayjs(new Date(value)).set('hour', 9).set('minute', 30))
            setMaxTime(dayjs(new Date(value)).set('hour', 16))
        } else {
            setMinTime(dayjs(new Date(value)).set('hour', 9).set('minute', 30))
            setMaxTime(dayjs(new Date(value)).set('hour', 18))
        }
        setDateSelected(value);
    }

    const CustomActionBar = (props) => {
        const { onAccept } = props;

        const onDownloadButtonClick = () => {
            window.open(encodeURI('https://ibc.kinedev.it/Kineton MIT Expertise.pdf'), '_blank');
        }

        const checkDisabledButton = () => {
            if (completed) return true;
            if (dateSelected === null) return true;
            const isDateGood = dateSelected.$H >= 9 && dateSelected.$H <= 16;
            const areFieldsGood = email !== undefined && email !== '' && company !== undefined && company !== '';
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return !(isDateGood && areFieldsGood && emailRegex.test(email));
        }

        return (
            <Grid container xs={12} sm={12} lg={12} xl={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} style={{ padding: '15px' }}>
                <IconButton aria-label="delete" size="large" onClick={onDownloadButtonClick}>
                    <Download fontSize="inherit" />
                </IconButton>
                {completed ?
                    <AddToCalendarButton
                        styleDark="--btn-background: #000;"
                        name={`Kineton <> ${document.getElementById('company').value} @IBC 2024`}
                        customCss='./add-to-calendar.css'
                        buttonStyle='custom'
                        listStyle="modal"
                        options="'Apple', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'"
                        location="Booth 1.C37f"
                        startDate={data.startDate}
                        endDate={data.endDate}
                        startTime={data.startTime}
                        endTime={data.endTime}
                        timeZone="Europe/Rome"
                    />
                    :
                    <LoadingButton
                        disabled={checkDisabledButton()}
                        variant="contained"
                        size="medium"
                        onClick={(e) => submit(e, onAccept)}
                        sx={{ fontWeight: 700 }}
                        loading={loading}
                    >Confirm</LoadingButton>
                }
            </Grid>
        )
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main">
                <Grid container display={"flex"} justifyContent={"center"} xs={12} flexDirection={'column'} alignItems={'center'} component={'form'}>
                    <Grid container xs={12} sm={8} lg={6} xl={5} display={"flex"} justifyContent={"center"} style={{ backgroundColor: "white", borderRadius: "7px", overflow: 'hidden' }}>
                        <Grid container xs={12} sm={12} lg={12} xl={12} display={"flex"} justifyContent={"center"} flexDirection={'column'} padding={3} >
                            <Grid container display={"flex"} justifyContent={"space-between"} flexDirection={'row'} alignItems={'center'} pb={4}>
                                <Typography p variant='h6' sx={{ fontWeight: 700, padding: 0 }}>
                                    Contact information
                                </Typography>
                                <img src="./K.png" width={17} />
                            </Grid>
                            <TextField
                                required
                                type='text'
                                id="fullName"
                                name="fullName"
                                label="Full Name"
                                fullWidth
                                size='small'
                                disabled={completed}
                                value={fullName}
                                sx={{ paddingBottom: 3 }}
                                autoComplete="name"
                                onChange={(e) => setFullName(e.target.value)}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Person />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                required
                                type='email'
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                size='small'
                                disabled={completed}
                                value={email}
                                sx={{ paddingBottom: 3 }}
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AlternateEmail />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                required
                                type='text'
                                id="company"
                                name="company"
                                size='small'
                                label="Company"
                                disabled={completed}
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                error={emailError}
                                fullWidth
                                autoComplete="company"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Business />
                                        </InputAdornment>
                                    )
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <StaticDateTimePicker
                                    orientation="portrait"
                                    minDate={dayjs('2024-09-13')}
                                    maxDate={dayjs('2024-09-16')}
                                    views={['day', 'hours', 'minutes']}
                                    minTime={minTime}
                                    maxTime={maxTime}
                                    disablePast={true}
                                    value={dateSelected}
                                    disabled={completed}
                                    onChange={onDateCalendarChange}
                                    onAccept={(value, context) => {
                                        console.log('onAccept', value, context)
                                    }}
                                    ampm={false}
                                    ampmInClock={true}
                                    minutesStep={15}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock
                                    }}
                                    slots={{
                                        actionBar: CustomActionBar
                                    }}
                                    slotProps={{
                                        // calendarHeader: { sx: { height: 0 } },

                                        tabs: {
                                            hidden: false,
                                        },
                                        toolbar: {
                                            hidden: false
                                        },
                                        actionBar: {
                                            actions: ['accept'],
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar open={snackbarOpened} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        <span>Your appointment has been confirmed!</span> <br />
                        <span>See you @ <a href="https://ibc.expocad.com/Events/ibc24/index.html?zoomto=1.C37f" style={{ color: 'white', fontWeight: 700 }} target='_blank'>Booth 1.C37f</a></span>
                    </Alert>
                </Snackbar>
                <Snackbar open={errorSnackbarOpened} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        <span>Ooops, something goes wrong. Please, retry</span>
                    </Alert>
                </Snackbar>
            </Container >
        </ThemeProvider >
    )
}

export default App;